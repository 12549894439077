import React, { Component } from "react"
import { Helmet } from "react-helmet"
import { ContactForm, Layout } from "../../../components"
import { Medailon, PaddingWrapper } from "@igloonet-web/shared-ui"
import Logo from "../../../images/reference/fektvut/logofekt.png"
import BGFEKT from "../../../images/reference/bg-fekt.png"
import Avatar from "../../../images/reference/fektvut/avatar.jpg"
import ReferenceHeader from "../../../components/reference-detail/reference-header"
import ReferenceImageText from "../../../components/reference-detail/reference-image-text"
class FEKT extends Component {
  constructor({ props }) {
    super(props)
  }

  render() {
    return (
      <Layout>
        <Helmet>
          <title>Reference na marketingové práce pro FEKT VUT | igloonet</title>
          <meta
            name="description"
            content="Jak se nám povedlo zastavit propad podaných přihlášek u Fakulty elektrotechniky? S FEKTem dlouhodobě spolupracujeme na marketingu sociálních sítí. Cílem je oslovovat potenciální studenty v průběhu celého roku."
          />
        </Helmet>
        <ReferenceHeader
          companyName="fekt vut"
          heading="Zastavit propad počtu podaných přihlášek"
          date="2016 - 2021"
        />

        <div className="pb-5 mb-5">
          <ReferenceImageText
            halfImgSize
            right
            img={BGFEKT}
            eager
            alt=""
            logo={Logo}
            logoAlt="logo FEKT VUT"
            link="//fekt.vut.cz/"
          >
            <p>
              Jak se nám povedlo zastavit propad podaných přihlášek u Fakulty
              elektrotechniky? S FEKTem dlouhodobě spolupracujeme na marketingu
              sociálních sítí. Cílem je oslovovat potenciální studenty v průběhu
              celého roku.
            </p>
          </ReferenceImageText>
        </div>

        <PaddingWrapper>
          <Medailon
            personName="doc. Ing. Jiří Háze, Ph.D."
            position="Proděkan pro vnější vztahy a zahraniční styky, Vedoucí ústavu"
            img={Avatar}
          >
            <p>
              Spolupráce se společností Igloonet je z našeho pohledu velmi
              přínosná. Musíme vyzdvihnout profesionalitu pracovníků firmy,
              jejich kreativitu a nápady, které pomáhají fakultě řešit problém
              nedostatku dobrých studentů na technické univerzitě. Díky
              spolupráci se fakultě daří uchazeče o studium oslovovat
              atraktivním způsobem a se zapálením pro elektrotechniku.
            </p>
          </Medailon>
        </PaddingWrapper>
        <ContactForm contact="adam" />
      </Layout>
    )
  }
}

export default FEKT
